import { logException } from '../../shared/lib/logging.ts';

export const ATTRIBUTE_WEB_SHARE = 'data-cs-web-share';

export default function loadWebShare($buttons: NodeListOf<HTMLButtonElement>) {
	$buttons.forEach(($button) => {
		if (navigator.share) {
			$button.addEventListener('click', async () => {
				try {
					await navigator.share({
						url:
							$button.getAttribute(`${ATTRIBUTE_WEB_SHARE}-url`) ??
							document.querySelector<HTMLMetaElement>('meta[property="og:url"]')?.content,
						title:
							$button.getAttribute(`${ATTRIBUTE_WEB_SHARE}-title`) ??
							document.querySelector<HTMLMetaElement>('meta[property="og:title"]')?.content,
						text:
							$button.getAttribute(`${ATTRIBUTE_WEB_SHARE}-text`) ??
							document.querySelector<HTMLMetaElement>('meta[property="og:description"]')?.content,
					});
				} catch (e) {
					logException(e);
				}
				$button.parentElement?.classList.remove('d-none');
			});
		} else {
			$button.parentElement?.remove();
		}
	});
}

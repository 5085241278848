import { postLog } from './api.ts';

const useApiLogging = false;
const useConsoleLogging = !process.env.IS_NODE_ENV_PROD;

type LogLevel = 'CRITICAL' | 'ERROR' | 'WARNING' | 'INFO' | 'DEBUG';

function logToApi(level: LogLevel, message: string) {
	if (useApiLogging) {
		postLog({ level, message }).catch(() => {
			// Noop
		});
	}
}

function logToConsole(level: LogLevel, message: string) {
	if (useConsoleLogging) {
		if (['CRITICAL', 'ERROR', 'WARNING'].includes(level)) {
			console.error(`[${level}] ${message}`);
		} else {
			console.log(`[${level}] ${message}`);
		}
	}
}

export function log(level: LogLevel, message: string) {
	logToApi(level, message);
	logToConsole(level, message);
}

export function logError(message: string) {
	log('ERROR', message);
}

export function logException(exception: unknown) {
	logError((exception as Error).toString());
}

export function createAndLogException(message: string) {
	const error = new Error(message);
	logException(error);
	return error;
}

const element = document.querySelector<HTMLDivElement>('[data-cs-prize-game]');

if (element) {
	import('./prize-game.ts').then(async ({ default: PrizeGame }) => {
		const prizeGame = new PrizeGame(element);
		await prizeGame.initialize();
	});
}

export {};
